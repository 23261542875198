<template>
    <div>
        <div class="pagination__items clearfix pb-3 text-right">
            <span class="pagination__items__label mr-4"> {{ configPage.total }} 件中 {{ from }}-{{ to }} 件表示 </span>
            <span>表示件数</span>
            <select v-model="configPage.limit" class="pagination__items__select ml-2" @change="onChange">
                <option v-for="value in perPageList" :key="value" :label="value + '件'" :value="value"></option>
            </select>
        </div>

        <div class="table-responsive" :style="`min-height:${isLoading ? '60vh' : `${listDataTable.length > 0 && !isLoading ? '100%' : 'auto'}`}`">
            <table class="table table-bordered table-box table-hover">
                <thead>
                    <tr v-if="config">
                        <th
                            v-for="(item, k) in config.headers"
                            :key="k"
                            class="text-left"
                            :colspan="item.colSpan ?? 1"
                            :style="item.width ? `width:` + item.width : ''"
                        >
                            <div class="flex items-center">
                                {{ item.label }}
                                <span v-if="item.required" class="text-danger">*</span>
                                <span v-if="item.sort" :class="`sort ${item.sort.type ? item.sort.type : 'default'}`" @click="handleSort(item)">
                                    <i class="fas fa-sort-up"></i>
                                    <i class="fas fa-sort-down"></i>
                                </span>
                            </div>
                        </th>
                        <th :class="`text-center ${stickyAction ? 'sticky_action' : ''}`" v-if="visibleAction">
                            <!-- {{ $t('tableBox.action') }} -->
                        </th>
                    </tr>
                    <slot v-else name="head"></slot>
                </thead>
                <tbody v-if="listDataTable.length > 0 && !isLoading">
                    <template v-for="(row, index) in listDataTable">
                        <Transition name="slide-fade__table--list" appear :key="index">
                            <slot
                                :index="index + 1 + (configPage.page - 1) * configPage.limit"
                                :row="row"
                                :leng="row?.confirm?.length ? row?.confirm?.length : 1"
                                class="text-left"
                                name="row"
                            ></slot>
                        </Transition>
                    </template>
                </tbody>
                <tbody v-else-if="isLoading">
                    <template>
                        <tr style="position: relative; height: 80px">
                            <td style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); border: none">
                                <div class="d-flex justify-content-center">
                                    <LoadingIcon />
                                </div>
                            </td>
                        </tr>
                    </template>
                </tbody>
                <tbody v-else>
                    <template>
                        <Transition name="slide-fade__table--list" appear>
                            <tr style="position: relative; height: 80px">
                                <td style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); border: none">
                                    <p class="mt-3 text-center">
                                        {{ $t('notificationCommon.searchEmpty') }}
                                    </p>
                                </td>
                            </tr>
                        </Transition>
                    </template>
                </tbody>
            </table>
        </div>

        <Pagination
            v-if="configPage.total && listDataTable.length > 0 && !isLoading"
            :total="configPage.total"
            :page.sync="configPage.page"
            :per-page="configPage.limit"
            @changePage="onChangePage"
        ></Pagination>
    </div>
</template>

<script>
import { listPaging } from '@/state/helpers';

import Pagination from '@/components/Pagination/main.vue';
import LoadingIcon from '@/components/Loading/main.vue';

export default {
    name: 'TableListComponent',
    components: {
        LoadingIcon,
        Pagination
    },
    props: {
        config: {
            type: Object,
            require: true
        },
        dataList: {
            type: Array,
            require: true,
            default: () => []
        },
        reload: {
            type: Boolean,
            default: () => false
        },
        isLoading: {
            type: Boolean,
            default: () => false
        },
        visibleAction: {
            type: Boolean,
            required: false,
            default: true
        },
        stickyAction: {
            type: Boolean,
            required: false,
            default: false
        },
        configPage: {
            type: Object,
            default: () => null
        }
    },
    computed: {
        from: function () {
            return (this.configPageDefault.page - 1) * this.configPageDefault.limit + 1;
        },
        to: function () {
            let to = this.configPageDefault.page * this.configPageDefault.limit;
            return to > this.configPageDefault.total ? this.configPageDefault.total : to;
        }
    },
    data() {
        return {
            configPageDefault: this.configPage,
            perPageList: listPaging,
            listDataTable: this.dataList
        };
    },

    methods: {
        onChange() {
            this.configPageDefault.page = 1;
            this.$emit('onChangePage');
        },

        handleSort(sortItem) {
            if (sortItem.sort.type == 'asc') {
                sortItem.sort.type = 'desc';
            } else if (sortItem.sort.type == 'desc') {
                sortItem.sort.type = 'asc';
            } else {
                sortItem.sort.type = 'desc';
            }
            this.$emit('sort', sortItem);
        },

        onChangePage() {
            this.$emit('onChangePage');
        }
    },

    watch: {
        dataList: {
            handler: function (newData) {
                this.listDataTable = newData;
            },
            immediated: true,
            deep: true
        },
        configPage: {
            handler: function (newConfig) {
                this.configPageDefault = newConfig;
            },
            immediated: true,
            deep: true
        },

        configPageDefault: {
            handler: function (newConfig) {
                this.$emit('update:configPage', newConfig);
            },
            immediated: true,
            deep: true
        }
    }
};
</script>

<style lang="scss" scoped>
.sort.default {
    color: lightgray;
}
</style>
